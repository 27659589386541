.main {
  justify-self: start;
  align-self: start;
  width: calc((100vw - var(--frame-width)*2.7) / 3);
  height: calc((100vw - var(--frame-width)*2.7) / 3);
  padding-top: calc(var(--frame-width) / 2);
  padding-bottom: calc(var(--frame-width) / 2);
  display: grid;
  grid-template-rows: calc(88vw / 3);
  position: relative;
}

.information {
  padding-top: 10%;
  text-align: center;
  height: 10%;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0.6vh;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 500;
}

@media screen and (max-width: 1023px) {
  .main {
    width: calc(100vw / 2);
    height: calc(100vw / 2);
    grid-template-rows: 80% 20%;
    padding: 0;
    padding-bottom: 10vh;
  }

  .information {
    padding: 0;
    font-size: inherit;
    padding-bottom: calc(var(--frame-width) / 2);
    bottom: 7.6vh;
  }
}
