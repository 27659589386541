.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
      "item2"
      "item1";
  height: 100vh;
}

.left {
  grid-area: item1;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.right {
  grid-area: item2;
  width: 100vw;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .main {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 
      "item1 item2";
  }
  .right {
    width: 50vw;
  }
  .left {
    height: 100vh;
    width: 50vw;
  }
}