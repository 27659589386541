.main {
  padding: var(--frame-width);
  overflow: auto;
}

.main p {
  padding: 0;
  margin: 0;
  font-size: 1.1em;
}

.main h2 {
  font-size: 0.8em;
  font-weight: normal;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}

.main h3 {
  font-size: 0.8em;
  font-weight: normal;
  padding: 0;
  text-align: center;
}

.left {
  align-self: start;
  height: 100%;
  display: grid;
  font-size: 3.5rem;
  text-align: center;
}

.right {
  height: calc(100vh - (var(--frame-width) * 3));
  padding-top: var(--frame-width);
  font-size: 3.5rem;
  display: grid;
}

.about {
  align-self: start;
  padding: 0;
  padding-top: var(--frame-width);
  line-height: 150%;
  font-size: 3rem;
}

.rest {
  align-self: start;
  padding: 0;
  padding-top: calc(var(--frame-width) * 2);
  font-size: 2.5rem;
  line-height: 130%;
}

@media screen and (max-width: 700px) {
  .about {
    align-self: start;
    padding: 0;
    padding-top: var(--frame-width);
    line-height: 150%;
    font-size: 3.5rem;
  }

  .rest {
    align-self: start;
    padding: 0;
    padding-top: calc(var(--frame-width) * 2);
    font-size: 3rem;
    line-height: 130%;
  }
}

@media screen and (min-width: 1024px) {
  .left {
    align-self: start;
    font-size: 1.8rem;
    height: calc((var(--vh, 1vh) * 100) - (var(--frame-width) * 2));
  }
  .right {
    margin: 0;
    padding: 0;
    align-self: start;
    font-size: 1.8rem;
    height: calc((var(--vh, 1vh) * 100) - (var(--frame-width) * 2));
  }
  .about {
    align-self: center;
    padding: 0;
    padding-left: calc(var(--frame-width) / 2);
    padding-bottom: 0;
    text-align: left;
    font-size: 1.5rem;
  }

  .rest {
    align-self: center;
    padding: 10vw;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    line-height: 150%;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1700px) {
  .left {
    font-size: 1.2rem;
    padding: 0;
  }
  .right {
    font-size: 1.2rem;
    padding: 0;
  }
  .about {
    font-size: 1rem;
  }

  .rest {
    font-size: 1rem;
  }
}
