.main {
  padding: var(--frame-width);
  position: fixed;
  left: 0;
  top: 0;
  width: calc(50vw - (var(--frame-width) * 2));
  height: 100vh;
  z-index: 3;
  background: #f4f4f4;
  font-size: 0.75rem;
  text-transform: uppercase;
  transition: left var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  overflow-y: scroll;
  color: #9c9c9c;
  pointer-events: initial;
}

.frame {
  padding: 1.5vw;
  padding-left: 2.5vw;
}

.title {
  font-size: 1em;
}

.itemsCollections {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 20vh;
  font-size: 2em;
  padding: 0.5vw 0;
  columns: 10vw 2;
  column-gap: 1vw;
}

.items {
  font-size: 2em;
  padding: 0.5vw 0;
}

.item {
  padding: 0.3vw 0;
}

.copyright {
  position: absolute;
  bottom: calc(var(--frame-width) * 3);
  left: calc(var(--frame-width) + 2.5vw);
  font-size: 0.9em;
}

@media screen and (max-width: 1023px) {
  .main {
    width: calc(100vw - (var(--frame-width) * 5));
    font-size: 0.5rem;
  }
  .title {
    font-size: 3em;
  }

  .itemsCollections {
    max-height: 23vh;
    font-size: 7em;
    padding: 1vw 0;
    columns: 20vw 2;
    column-gap: 5vw;
  }

  .items {
    font-size: 7em;
    padding: 1vw 0;
  }
  .copyright {
    position: absolute;
    bottom: calc(var(--frame-width) * 4);
    left: calc(var(--frame-width) + 2.5vw);
    font-size: 3em;
  }
}

@media screen and (max-width: 700px) {
  .main {
    width: calc(100vw - (var(--frame-width) * 5));
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1800px) {
  .main {
    font-size: 0.6rem;
  }
}
