.main {
  position: relative;
  z-index: 0;
  /* overflow: hidden; */
}

@media screen and (max-width: 1023px) {
  .main {
    width: 100vw;
  }
}
