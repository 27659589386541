.main {
}

.dot {
  position: absolute;
  left: -1.5vw;
  top: 0.9vw;
  height: 0.5vw;
  width: auto;
  box-sizing: border-box;
  /* padding: 0.5vw; */
  /* background: blue; */
  overflow: visible;
  display: flex;
  justify-content: center;
}

.realDot {
  width: 0.1vw;
  height: 0.1vw;
}

.item {
  padding: 0.3vw 0;
  position: relative;
  /* border: 1p x solid blue; */
}

@media (hover) {
  .item:hover {
    color: #4a4a4a;
  }
}

@media screen and (max-width: 1023px) {
  .dot {
    left: -4vw;
    top: 1.3vw;
    height: 3vw;
    width: 2vw;
  }
  .item {
    padding: 0.7vw 0;
    position: relative;
  }
}

@media screen and (max-width: 700px) {
  .item {
    padding: 1.2vw 0;
    position: relative;
  }
  .dot {
    top: 2.8vw;
  }
}

@media screen and (min-width: 1700px) {
  .dot {
    left: -1.5vw;
    top: 0.8vw;
    height: 0.5vw;
    width: auto;
  }
}
