.main {
  transition: opacity var(--ani-time) ease-in-out;
  opacity: 1;
  background: black;
  height: 200vh;
  width: 100vw;
  overflow: hidden;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}
