@font-face {
  font-family: Opale;
  src: url('./fonts/Opale-Regular.woff');
}

a:hover {
  text-decoration: none;
  color: inherit;
}

body,
html {
  padding: 0;
  margin: 0;
  font-family: Opale, sans-serif;
  font-size: 1vw;
  line-height: 1.25;
  letter-spacing: 0.95px;
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* @media screen and (max-width: 1024px) {
  body,
  html {
    font-size: 1.1vw;
  }
}

@media screen and (max-width: 700px) {
  body,
  html {
    font-size: 1vw;
  }
}

@media screen and (min-width: 1700px) {
  body,
  html {
    font-size: 23px;
  }
} */
