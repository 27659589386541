:root {
  --frame-width: 3vw;
}

.main {
  user-select: none;
  width: 100vw;
  height: 100vh;
  font-size: 1rem;
}

.darken {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* transition: opacity 200ms cubic-bezier(0.01, 0.71, 0.46, 0.09); */
}

.horizontalTop {
  position: absolute;
  top: 0;
  height: var(--frame-width);
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  pointer-events: initial;
  z-index: 2;
}

.verticalLeft {
  position: absolute;
  top: 0;
  left: 100;
  height: 100vh;
  width: var(--frame-width);
  display: grid;
  pointer-events: initial;
}

.verticalRight {
  position: absolute;
  top: 0px;
  right: 0;
  height: 100vh;
  width: var(--frame-width);
  display: grid;
  pointer-events: initial;
  z-index: 5;
  pointer-events: none;
}

@media (hover) {
  .shop:hover,
  .logo:hover,
  .item:hover,
  .about:hover,
  .cart:hover,
  .menu:hover {
    opacity: var(--hover);
  }
}

.logo {
  align-self: center;
  padding-left: calc(var(--frame-width) / 2);
  height: calc(var(--frame-width) / 2);
  justify-self: end;
  position: relative;
  top: calc(var(--frame-width) / 4);
  position: relative;
  fill: var(--logo-color);
}

.category {
  justify-self: center;
  align-self: center;
}

.item {
  padding: 0 0.5vw;
  text-transform: uppercase;
}

.about {
  justify-self: end;
  align-self: center;
  padding-right: calc(var(--frame-width) / 2);
  z-index: 1;
}

.cart {
  writing-mode: tb-rl;
  justify-self: center;
  align-self: center;
  position: relative;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: -1;
  pointer-events: initial;
}

.menu {
  writing-mode: tb-rl;
  justify-self: center;
  align-self: center;
  transform: translateY(-50%) rotate(180deg);
  cursor: pointer;
  z-index: 901;
  color: var(--logo-color);
}

.menu g {
  stroke: var(--text-color);
}

@media screen and (max-width: 1024px) {
  :root {
    --frame-width: 5vw;
  }
  .main {
    font-size: 3rem;
  }
  .horizontalTop {
    height: calc(var(--frame-width) * 2.5);
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    z-index: 20;
    position: fixed;
    top: 0;
  }

  .horizontalBottom {
    height: var(--frame-width);
    grid-template-columns: 1fr;
  }

  .verticalLeft {
    width: var(--frame-width);
  }

  .verticalRight {
    width: var(--frame-width);
  }

  .cart {
    writing-mode: initial;
    justify-self: end;
    align-self: center;
    position: relative;
    /* top: -1vw; */
    z-index: 901;
    padding-right: calc(var(--frame-width));
    font-size: 1em;
    transform: translateY(0);
  }

  .cartCircle {
    position: absolute;
    right: calc(var(--frame-width) / 2);
    bottom: calc(var(--frame-width) / 4);
  }

  .cartText {
    position: absolute;
    right: calc((var(--frame-width) / 2) + 0.7vw);
    bottom: calc((var(--frame-width) / 4) + 0.2vw);
  }

  .menu {
    justify-self: start;
    align-self: center;
    margin-left: calc(var(--frame-width) / 2);
    height: calc(var(--frame-width) / 1);
    color: var(--text-color);
    /* border: 1px solid green; */
  }

  .logo {
    height: calc(var(--frame-width));
    padding-top: calc(var(--frame-width) / 2);
    justify-self: center;
    fill: var(--text-color);
    /* border: 1px solid blue; */
  }
}

@media screen and (max-width: 700px) {
  .main {
    font-size: 4rem;
  }
  .logo {
    height: calc(var(--frame-width) * 1.5);
  }
  .cart {
    font-size: 1.4em;
  }
  .logo {
    padding-top: calc(var(--frame-width) / 4);
    /* border: 1px solid blue; */
  }
}
