.main {
  width: 100vw;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.slider {
  position: relative;
  z-index: 0;
}

.slider > * {
  position: static;
}

.framePhone {
  width: 100vw;
  height: auto;
}

.frameDesktop {
  width: 100vw;
  height: 100vh;
}

.body {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  /* transition: transform var(--ani-time) cubic-bezier(.18,.63,.96,.51); */
  background: var(--background-color);
}

.horizontalBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  height: var(--frame-width);
  width: 100vw;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 10;
}

.description {
  justify-self: center;
  align-self: center;
  cursor: pointer;
}

@media (hover) {
  .description:hover,
  .lookbook:hover {
    opacity: var(--hover);
  }
}

.lookbook {
  justify-self: center;
  align-self: center;
  cursor: pointer;
}

/* .transition-down-appear {
  transform: translateY(100vh);
} */

.transition-down-appear {
  top: 0;
  position: absolute;
  transform: translateY(0);
}

.transition-down-enter {
  top: 0;
  position: absolute;
  transform: translateY(100vh);
}

.transition-down-enter-active {
  position: unset;
  transform: translateY(0);
  transition: transform 1000ms ease-out;
}

.transition-down-exit {
  top: 0;
  position: absolute;
  transform: translateY(0);
}

.transition-down-exit-active {
  transform: translateY(-100vh);
  transition: all 1000ms ease-out;
}

.transition-down-appear {
  top: 0;
  position: absolute;
  transform: translateY(0);
}

.transition-up-enter {
  position: absolute;
  top: 0;
  transform: translateY(-100vh);
}

.transition-up-enter-active {
  transform: translateY(0);
  transition: all 1000ms ease-out;
}

.transition-up-exit {
  top: 0;
  position: absolute;
  transform: translateY(-100vh);
}

.transition-up-exit-active {
  transform: translateY(100vh);
  transition: all 1000ms ease-out;
}

.transition-down-appear {
  top: 0;
  position: absolute;
  transform: translateY(50vh);
}

.transition-change-enter {
  position: relative;
  top: -50vh;
  transform: translateY(0);
}

.transition-change-enter-active {
  position: relative;
  top: -100vh;
  transform: translateY(0);
  transition: all 1000ms ease-out;
}

.transition-change-exit {
  top: -100vh;
  position: relative;
  transform: translateY(-150vh);
}

.transition-change-exit-active {
  top: -200vh;
  position: absolute;
  transform: translateY(-50vh);
  transition: all 1000ms ease-out;
}

@media screen and (max-width: 1024px) {
  .horizontalBottom {
    grid-template-columns: auto auto;
  }
  .description {
    position: absolute;
    right: calc(var(--frame-width) / 4);
    top: 50%;
    writing-mode: tb-rl;
    transform: translateY(-50%);
    z-index: 10;
  }
  .lookbook {
    position: absolute;
    left: calc(var(--frame-width) / 4);
    top: 50%;
    writing-mode: tb-rl;
    transform: translateY(-50%) rotate(180deg);
    z-index: 10;
  }
}
