.main {
  /* transition: opacity var(--ani-time) ease-in-out;
  opacity: 1; */
  background: var(--background-color);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  font-size: 1rem;
}

.logo {
  width: 100%;
  height: auto;
  padding: 1vw;
  overflow: visible;
  fill: #9c9c9c;
}

.realLogo {
  overflow: visible;
  width: 100%;
  height: auto;
}

.text {
  position: relative;
  top: -5vh;
  align-self: center;
  width: 50vw;
  line-height: 3rem;
  text-align: justify;
}

.vejome {
  position: absolute;
  top: 0.8vw;
  text-align: center;
}

.jewelry {
  position: fixed;
  bottom: 0.8vw;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  .main {
    font-size: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .main {
    font-size: 4rem;
  }
}
