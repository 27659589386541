.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background-color);
}

.slider {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
  z-index: 500;
}

.left {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 800;
  height: 100%;
  width: 50%;
}

.right {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 800;
  height: 100%;
}

.leftArrow {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 6rem;
  z-index: 600;
  padding: var(--frame-width);
  transform: translateY(-50%);
}

.rightArrow {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 6rem;
  z-index: 600;
  padding: var(--frame-width);
  transform: translateY(-50%);
}

.selector {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 500;
  height: auto;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--logo-color);
  padding-bottom: calc(var(--frame-width) / 4);
}

.item {
  padding: 0.5vw;
  padding-bottom: 0;
  padding-top: 0;
  cursor: pointer;
}

.transition {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.transition-appear {
  opacity: 1;
}

.transition-enter {
  opacity: 1;
}

.transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.transition-exit {
  opacity: 1;
  background: var(--background-color);
}

.transition-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.transition-exit-done {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.circle {
  height: auto;
  width: 0.4vw;
}

@media screen and (max-width: 1023px) {
  .circle {
    height: auto;
    width: 1.5vw;
  }
}
