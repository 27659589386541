.main {
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image {
  position: relative;
  height: var(--PcColumnHeight);
  transform: (translate(0, 0, 0));
  transition: transform 500ms, skew 500ms, rotateZ 500ms;
}

.productInfo {
  text-align: center;
  font-size: 0.8rem;
  width: auto;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .main {
    height: 100%;
    display: initial;
    overflow: hidden;
  }
  .image {
    height: 100%;
  }
}
