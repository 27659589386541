.main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  height: calc(100vh - (var(--frame-width)*2));
  grid-gap: 1vw 1vw;
  padding: var(--frame-width);
  padding-top: calc(var(--frame-width)*2);
}

@media screen and (max-width: 1023px) {
  .main {
    grid-template-columns: auto auto;
    padding: 0;
    padding-top: calc(var(--frame-width)*2);
  }
}