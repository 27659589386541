.main {
  display: grid;
  font-size: 2vmin;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1023px) {
  .main {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
}