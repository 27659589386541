.main {
  padding: var(--frame-width);
  padding-top: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(50vw - (var(--frame-width) * 2));
  height: 100vh;
  z-index: 5;
  background: #f4f4f4;
  font-size: 0.9rem;
  text-transform: uppercase;
  transform: translateY(100vh);
  transition: transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  display: grid;
  grid-template-rows: 1fr;
  color: #9c9c9c;
  /* overflow-y: scroll; */
}

.body {
  justify-self: center;
  align-self: center;
  padding: var(--frame-width);
  transform: translateY(-10%);
}

.title {
  text-align: center;
}

.year {
  text-align: center;
}

.subtext {
  text-align: center;
}

.description {
  font-size: 1.5rem;
  padding: 1vw;
  text-align: justify;
  text-transform: initial;
}

.copyright {
  position: fixed;
  bottom: var(--frame-width);
  left: calc(var(--frame-width) + 2.5vw);
}

@media screen and (max-width: 1023px) {
  .main {
    width: calc(100vw - (var(--frame-width) * 5));
    font-size: 2rem;
  }
  .description {
    font-size: 3rem;
    padding: 1vw;
    text-align: justify;
  }
}

@media screen and (max-width: 700px) {
  .body {
    transform: translateY(-20%);
  }
  .main {
    width: calc(100vw - (var(--frame-width) * 5));
    font-size: 3rem;
  }
  .description {
    font-size: 4rem;
    padding: 1vw;
    text-align: justify;
  }
}
