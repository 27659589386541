.main {
  display: grid;
  grid-template-rows: 2% auto auto;
  padding: var(--frame-width);
  padding-top: 6vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.title {
  text-align: center;
}

.description {
  font-size: 1.5rem;
  /* padding: 2vw; */
  text-align: justify;
  text-transform: initial;
}

.extra {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  font-size: 1rem;
  column-gap: 2vw;
}

.extraTitle {
  padding: 1vw;
  text-align: center;
  text-transform: uppercase;
}

.extraGroup {
  justify-self: center;
  width: 100%;
  box-sizing: border-box;
}

.fillItem {
  display: none;
}

@media screen and (max-width: 1023px) {
  .main {
    padding-top: 15vw;
    font-size: 3rem;
    height: 100%;
  }
  .description {
    padding: var(--frame-width);
    font-size: 3.5rem;
  }
  .extra {
    padding: var(--frame-width);
  }
  .extra {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    font-size: 3.5rem;
  }
  .extraTitle {
    padding-top: 6vw;
  }

  .fillItem {
    height: 8vh;
    width: 100%;
    display: initial;
  }
}

@media screen and (max-width: 700px) {
  .main {
    padding-top: 15vw;
    font-size: 4.5rem;
    overflow: scroll;
    height: 100%;
  }
  .description {
    padding: var(--frame-width);
    font-size: 4.5rem;
  }
  .extra {
    padding: var(--frame-width);
  }
  .extra {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    font-size: 4.5rem;
  }
}
