:root {
  --background-color: #f3f3f3;
  --text-color: #9c9c9c;
  --ani-time: 700ms;
  --hover: 0.5;
}

button {
  background-color: transparent;
  width: auto;
  font-size: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--text-color);
  padding: 1vw;
  font-family: Opale, sans-serif;
  text-align: center;
  border-style: solid;
}

.app {
  display: flex;
  font-size: 1.3rem;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.input {
  float: right;
  display: flex;
  align-items: stretch;
}

@media screen and (max-width: 1024px) {
  :root {
    --ani-time: 400ms;
  }
  .app {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 700px) {
  :root {
    --ani-time: 300ms;
  }
  .app {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1700px) {
  .app {
    font-size: 23px;
  }
}
