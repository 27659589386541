.main {
  text-align: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding-top: calc(var(--frame-width) * 3);
  overflow: auto;
}

.main p {
  padding: 0;
  margin: 0;
  font-size: 1.1em;
}

.main h2 {
  font-size: 0.8em;
  font-weight: normal;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.fillDiv {
  height: 10vh;
}

@media screen and (min-width: 1024px) {
  .main {
    font-size: 1rem;
    padding-top: calc(var(--frame-width) * 1);
  }
}

@media screen and (min-width: 1700px) {
  .main {
    font-size: 1rem;
  }
}
