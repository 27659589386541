.main {
  width: 86vw;
  height: 90vh;
  padding-top: calc(var(--frame-width) / 4);
  z-index: auto;
  margin-left: 7vw;
  user-select: initial;
  overflow: auto;
}

.header {
  font-size: 0.85em;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  background: var(--background-color);
  height: auto;
  overflow-x: scroll;
  text-align: left;
  padding-top: 10vh;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}

.headerItem {
  padding-right: 0;
  cursor: pointer;
  display: inline-block;
}

.headerItemBlock {
  display: block;
  padding: 1vw;
}

.body {
  padding-top: calc(var(--frame-width) * 2);
  columns: 90vw 1;
  column-gap: 5vw;
  font-size: 0.8em;
  color: #d8d8d8;
}

.body p {
  padding: 0;
  margin: 0;
}

.body h2 {
  font-size: 1.1em;
  font-weight: normal;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  z-index: 100;
}

.body h3 {
  font-size: 1em;
  font-weight: normal;
  padding: 0;
  text-align: left;
}

@media (hover) {
  .headerItem:hover {
    opacity: var(--hover);
  }
}

@media screen and (min-width: 1024px) {
  .main {
    font-size: 2rem;
  }
  .header {
    position: fixed;
    font-size: 1rem;
    top: 0;
    left: calc(var(--frame-width) * 2);
    width: 87vw;
    height: var(--frame-width);
    overflow-x: scroll;
    text-align: center;
    padding-top: 0;
    flex-direction: row;
  }
  .headerItem {
    padding-right: 2vw;
  }
  .body {
    padding-top: calc(var(--frame-width) * 1.5);
    columns: 40vw 2;
    column-gap: 5vw;
    font-size: 0.5em;
  }
}

@media screen and (min-width: 1700px) {
  .main {
    font-size: 1.5rem;
  }
  .header {
    font-size: 1rem;
  }
}
