.main {
  position: relative;
  cursor: pointer;
  display: grid;
}

.ulList {
  position: absolute;
  bottom: var(--frame-width);
  width: 30vw;
  list-style-type: none;
  padding: 0;
}

.ulListItem {
  padding-top: 0.4vw;
  position: relative;
}

@media (hover) {
  .header:hover {
    opacity: var(--hover);
  }
  .ulListItem:hover {
    color: #4a4a4a;
  }
}

.dot {
  width: 0.5vw;
  position: absolute;
  left: -0.8vw;
  top: 0.2vw;
  height: 0.4vw;
}

.deepDot {
  overflow: visible;
}

@media screen and (max-width: 1024px) {
  .select {
    appearance: none;
    background: #e3e3e3;
    border: none;
    color: var(--text-color);
    font-size: 1em;
    font-family: Opale, sans-serif;
    text-transform: uppercase;
  }

  @media (hover) {
    .headerFrame:hover {
      opacity: 0.8;
    }
  }

  .headerFrame {
    display: grid;
    grid-template-columns: 90% 10%;
    width: 100%;
  }

  .plus {
    justify-self: end;
  }
}
