.main {
  height: 100vh;
  width: 100%;
  z-index: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translateY(100vh);
  transition: transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  overflow: visible;
  /* background: var(--background-color); */
  background: transparent;
}
