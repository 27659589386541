.main {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 75vh auto;
  height: 100%;
  position: relative;
  font-size: 3.5rem;
  text-transform: uppercase;
  z-index: 400;
  overflow-y: scroll;
}

.upperFrame {
  height: 100%;
  overflow: scroll;
  /* border-bottom: 1px solid #9c9c9c; */
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 0.3fr 1fr 0.7fr 0.9fr;
  grid-template-areas:
    'information'
    'price'
    'item1'
    'item2'
    'addtocart';
  align-self: center;
  padding-left: var(--frame-width);
  padding-right: var(--frame-width);
  padding-bottom: calc(var(--frame-width) / 2);
  padding-top: calc(var(--frame-width) / 2);
  position: relative;
  width: 100%;
  background: transparent;
  z-index: 900;
  box-sizing: border-box;
  background: #e3e3e3;
}

.information {
  grid-area: information;
  justify-self: center;
  align-self: start;
  cursor: pointer;
  font-size: 1.3em;
}

@media (hover) {
  .information:hover,
  .addToCart:hover {
    opacity: var(--hover);
  }
}

.formError {
  grid-area: formError;
  color: #ea5151;
  font-size: 0.7em;
  position: absolute;
  bottom: 15vw;
  width: 100%;
  text-align: center;
}

.addToCart {
  width: 100%;
  height: 7vw;
  text-align: center;
  font-size: 3.5rem;
  border: 1px solid var(--text-color);
  user-select: none;
}

.addToCartDiv {
  grid-area: addtocart;
  justify-self: center;
  align-self: end;
  padding-bottom: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  position: relative;
  user-select: none;
}

.shippingInformation {
  padding-top: 1vw;
  width: 100%;
  text-align: center;
  font-size: 0.6em;
}

.price {
  grid-area: price;
  justify-self: center;
  padding: 0;
  justify-self: center;
  text-align: center;
  grid-column: 1 / span 2;
}

.item1 {
  grid-area: item1;
  justify-self: center;
  padding-top: 4vh;
  padding-bottom: 1vh;
  text-align: justify;
  width: 60%;
}

.item2 {
  grid-area: item2;
  justify-self: center;
  padding-top: 1vh;
  padding-bottom: 1vh;
  text-align: justify;
  width: 60%;
}

@media screen and (min-width: 1024px) {
  .main {
    font-size: 1rem;
    overflow: hidden;
    grid-template-rows: calc(98vh - var(--frame-width)) auto var(--frame-width);
  }
  .form {
    display: grid;
    grid-template-columns: 20% 10% auto 10% 20%;
    grid-template-areas: 'item1 item2 information price addtocart';
    align-self: center;
    /* bottom: 0;
    right: 0; */
    height: auto;
    padding: 0;
    width: 100%;
    padding-left: calc(var(--frame-width) / 2);
    padding-right: calc(var(--frame-width) / 2);
    /* position: absolute; */
    background: var(--background-color);
  }

  .formError {
    bottom: 4vw;
    right: -17.3vw;
    width: 100%;
    text-align: center;
  }

  .price {
    padding-top: 0;
    padding-right: 0;
    text-align: center;
    justify-self: end;
    grid-column: 4 / span 1;
  }
  .information {
    justify-self: center;
    align-self: center;
    cursor: pointer;
    padding-top: 0;
    font-size: 1em;
  }

  .addToCartDiv {
    grid-area: addtocart;
    justify-self: center;
    align-self: end;
    padding-bottom: 0vh;
    display: flex;
    align-items: center;
    width: 100%;
    position: initial;
    padding-left: 1vw;
  }
  .addToCart {
    justify-self: end;
    align-self: center;
    font-size: 0.9rem;
    padding: 0.4vw;
    height: auto;
    vertical-align: middle;
    text-align: center;
  }

  .formError {
    left: -37.5%;
    bottom: 11.2vw;
  }

  .shippingInformation {
    padding-top: 0;
    position: relative;
    text-align: right;
    right: calc(var(--frame-width) / 2);
    font-size: 0.8rem;
  }

  .price {
    align-self: center;
    padding: 0;
  }

  .item1 {
    align-self: center;
    padding: 0;
    padding-right: 0;
    width: 100%;
    text-align: left;
  }
  .item2 {
    align-self: center;
    padding: 0;
    width: 100%;
  }
}
