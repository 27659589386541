.main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;
  width: 100%;
  /* height: calc(100vh - (var(--frame-width)*2)); */
  height: 100%;
  grid-template-areas:
    'item1 item2 item4 item5'
    'item1 item3 item4 item6';
  overflow: hidden;
  padding: var(--frame-width);
  position: absolute;
  /* bottom: 0; */
  left: 0;
  top: 0;
  transform: translateY(0);
  transition: transform var(--ani-time) cubic-bezier(0.18, 0.63, 0.96, 0.51);
  box-sizing: border-box;
}

.gridItem {
  align-self: start;
  justify-self: start;
  height: 100%;
}

.item1 {
  grid-area: item1;
  overflow: hidden;
}

.item2 {
  grid-area: item2;
}

.item3 {
  grid-area: item3;
}

.item4 {
  grid-area: item4;
  overflow: hidden;
}

.item5 {
  grid-area: item5;
}

.item6 {
  grid-area: item6;
}

@media screen and (max-width: 1023px) {
  .main {
    grid-template-columns: auto auto;
    grid-template-rows: 25% 25% 25% 25%;
    grid-template-areas:
      'item1 item1'
      'item3 item2'
      'item4 item4'
      'item6 item5';
    height: 90%;
    grid-gap: 0 0;
    padding-top: calc(var(--frame-width) * 2);
    box-sizing: border-box;
    width: 100%;
  }
  .gridItem {
    align-self: center;
    justify-self: center;
  }

  .item1 {
    transform: scale(1.6) rotate(90deg);
    position: relative;
    width: 15vw;
    box-sizing: border-box;
  }
  .item4 {
    transform: scale(1.6) rotate(90deg);
    position: relative;
    box-sizing: border-box;
    width: 15vw;
  }
}
