@font-face {
  font-family: Opale;
  src: url(/static/media/Opale-Regular.e302dd6a.woff);
}

a:hover {
  text-decoration: none;
  color: inherit;
}

body,
html {
  padding: 0;
  margin: 0;
  font-family: Opale, sans-serif;
  font-size: 1vw;
  line-height: 1.25;
  letter-spacing: 0.95px;
  height: 100%;
  width: 100%;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* @media screen and (max-width: 1024px) {
  body,
  html {
    font-size: 1.1vw;
  }
}

@media screen and (max-width: 700px) {
  body,
  html {
    font-size: 1vw;
  }
}

@media screen and (min-width: 1700px) {
  body,
  html {
    font-size: 23px;
  }
} */

.LoadingScreen_main__3maFa {
  /* transition: opacity var(--ani-time) ease-in-out;
  opacity: 1; */
  background: var(--background-color);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 1rem;
}

.LoadingScreen_logo__vXOqb {
  width: 100%;
  height: auto;
  padding: 1vw;
  overflow: visible;
  fill: #9c9c9c;
}

.LoadingScreen_realLogo__2IUIZ {
  overflow: visible;
  width: 100%;
  height: auto;
}

.LoadingScreen_text__21YLx {
  position: relative;
  top: -5vh;
  -webkit-align-self: center;
          align-self: center;
  width: 50vw;
  line-height: 3rem;
  text-align: justify;
}

.LoadingScreen_vejome__18S83 {
  position: absolute;
  top: 0.8vw;
  text-align: center;
}

.LoadingScreen_jewelry__pPmRk {
  position: fixed;
  bottom: 0.8vw;
  text-align: center;
}

@media screen and (max-width: 1023px) {
  .LoadingScreen_main__3maFa {
    font-size: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .LoadingScreen_main__3maFa {
    font-size: 4rem;
  }
}

:root {
  --background-color: #f3f3f3;
  --text-color: #9c9c9c;
  --ani-time: 700ms;
  --hover: 0.5;
}

button {
  background-color: transparent;
  width: auto;
  font-size: 2rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #9c9c9c;
  color: var(--text-color);
  padding: 1vw;
  font-family: Opale, sans-serif;
  text-align: center;
  border-style: solid;
}

.App_app__-rULS {
  display: -webkit-flex;
  display: flex;
  font-size: 1.3rem;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.App_input__2tpv4 {
  float: right;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
}

@media screen and (max-width: 1024px) {
  :root {
    --ani-time: 400ms;
  }
  .App_app__-rULS {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 700px) {
  :root {
    --ani-time: 300ms;
  }
  .App_app__-rULS {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1700px) {
  .App_app__-rULS {
    font-size: 23px;
  }
}

:root {
  --frame-width: 3vw;
}

.Frame_main__1hqgD {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100vw;
  height: 100vh;
  font-size: 1rem;
}

.Frame_darken__2Ebim {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* transition: opacity 200ms cubic-bezier(0.01, 0.71, 0.46, 0.09); */
}

.Frame_horizontalTop__2JQ7y {
  position: absolute;
  top: 0;
  height: 3vw;
  height: var(--frame-width);
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  pointer-events: initial;
  z-index: 2;
}

.Frame_verticalLeft__13xTQ {
  position: absolute;
  top: 0;
  left: 100;
  height: 100vh;
  width: 3vw;
  width: var(--frame-width);
  display: grid;
  pointer-events: initial;
}

.Frame_verticalRight__1TcHP {
  position: absolute;
  top: 0px;
  right: 0;
  height: 100vh;
  width: 3vw;
  width: var(--frame-width);
  display: grid;
  pointer-events: initial;
  z-index: 5;
  pointer-events: none;
}

@media (hover) {
  .Frame_shop__1-7j-:hover,
  .Frame_logo__2N2RV:hover,
  .Frame_item__1ydBu:hover,
  .Frame_about__21rLs:hover,
  .Frame_cart__3gUtn:hover,
  .Frame_menu__30Xzf:hover {
    opacity: var(--hover);
  }
}

.Frame_logo__2N2RV {
  -webkit-align-self: center;
          align-self: center;
  padding-left: calc(3vw / 2);
  padding-left: calc(var(--frame-width) / 2);
  height: calc(3vw / 2);
  height: calc(var(--frame-width) / 2);
  justify-self: end;
  position: relative;
  top: calc(3vw / 4);
  top: calc(var(--frame-width) / 4);
  position: relative;
  fill: var(--logo-color);
}

.Frame_category__3NoR- {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}

.Frame_item__1ydBu {
  padding: 0 0.5vw;
  text-transform: uppercase;
}

.Frame_about__21rLs {
  justify-self: end;
  -webkit-align-self: center;
          align-self: center;
  padding-right: calc(3vw / 2);
  padding-right: calc(var(--frame-width) / 2);
  z-index: 1;
}

.Frame_cart__3gUtn {
  -webkit-writing-mode: tb-rl;
          writing-mode: tb-rl;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
  position: relative;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1;
  pointer-events: initial;
}

.Frame_menu__30Xzf {
  -webkit-writing-mode: tb-rl;
          writing-mode: tb-rl;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
  cursor: pointer;
  z-index: 901;
  color: var(--logo-color);
}

.Frame_menu__30Xzf g {
  stroke: var(--text-color);
}

@media screen and (max-width: 1024px) {
  :root {
    --frame-width: 5vw;
  }
  .Frame_main__1hqgD {
    font-size: 3rem;
  }
  .Frame_horizontalTop__2JQ7y {
    height: calc(3vw * 2.5);
    height: calc(var(--frame-width) * 2.5);
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    z-index: 20;
    position: fixed;
    top: 0;
  }

  .Frame_horizontalBottom__4_2lZ {
    height: 3vw;
    height: var(--frame-width);
    grid-template-columns: 1fr;
  }

  .Frame_verticalLeft__13xTQ {
    width: 3vw;
    width: var(--frame-width);
  }

  .Frame_verticalRight__1TcHP {
    width: 3vw;
    width: var(--frame-width);
  }

  .Frame_cart__3gUtn {
    -webkit-writing-mode: initial;
            writing-mode: initial;
    justify-self: end;
    -webkit-align-self: center;
            align-self: center;
    position: relative;
    /* top: -1vw; */
    z-index: 901;
    padding-right: calc(3vw);
    padding-right: calc(var(--frame-width));
    font-size: 1em;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  .Frame_cartCircle__3TZ_M {
    position: absolute;
    right: calc(3vw / 2);
    right: calc(var(--frame-width) / 2);
    bottom: calc(3vw / 4);
    bottom: calc(var(--frame-width) / 4);
  }

  .Frame_cartText__1m0FC {
    position: absolute;
    right: calc((3vw / 2) + 0.7vw);
    right: calc((var(--frame-width) / 2) + 0.7vw);
    bottom: calc((3vw / 4) + 0.2vw);
    bottom: calc((var(--frame-width) / 4) + 0.2vw);
  }

  .Frame_menu__30Xzf {
    justify-self: start;
    -webkit-align-self: center;
            align-self: center;
    margin-left: calc(3vw / 2);
    margin-left: calc(var(--frame-width) / 2);
    height: calc(3vw / 1);
    height: calc(var(--frame-width) / 1);
    color: var(--text-color);
    /* border: 1px solid green; */
  }

  .Frame_logo__2N2RV {
    height: calc(3vw);
    height: calc(var(--frame-width));
    padding-top: calc(3vw / 2);
    padding-top: calc(var(--frame-width) / 2);
    justify-self: center;
    fill: var(--text-color);
    /* border: 1px solid blue; */
  }
}

@media screen and (max-width: 700px) {
  .Frame_main__1hqgD {
    font-size: 4rem;
  }
  .Frame_logo__2N2RV {
    height: calc(3vw * 1.5);
    height: calc(var(--frame-width) * 1.5);
  }
  .Frame_cart__3gUtn {
    font-size: 1.4em;
  }
  .Frame_logo__2N2RV {
    padding-top: calc(3vw / 4);
    padding-top: calc(var(--frame-width) / 4);
    /* border: 1px solid blue; */
  }
}

.ShoppingCart_main__1QGp9 {
  padding: var(--frame-width);
  position: fixed;
  right: 0;
  top: 0;
  width: calc(50vw - (var(--frame-width) * 2));
  height: 100vh;
  z-index: 4;
  background: #f4f4f4;
  font-size: 1.2rem;
  -webkit-transition: right var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  transition: right var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
}

.ShoppingCart_paid__14gBR {
  height: 100%;
  width: 60%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0 3vw 12vw 5vw;
  box-sizing: border-box;
}

.ShoppingCart_title__3uxyD {
  text-transform: uppercase;
}

.ShoppingCart_emptyCart__V8w8d {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: calc(100vh - var(--frame-width) * 4);
}

.ShoppingCart_emptyFrame__ZlbQU {
  align-self: center;
  text-align: center;
  display: grid;
  grid-template-rows: auto auto;
  width: 100%;
}

.ShoppingCart_emptyCartInfo__2XvsE {
  padding-top: calc(var(--frame-width) / 2);
  font-size: 1.5em;
  padding-bottom: 1vw;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}

.ShoppingCart_goShopping__1Jv2v {
  cursor: pointer;
  font-size: 1.5rem;
  border: solid 1px;
  display: grid;
  padding: 1vw;
  justify-self: center;
  align-self: end;
}

.ShoppingCart_goShoppingText__3-dX5 {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
}

.ShoppingCart_paypalSuccess__2fL1V {
  font-size: 2.5rem;
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
  padding-bottom: 3vw;
}

.ShoppingCart_continueShopping__1fGhy {
  text-align: right;
  cursor: pointer;
  padding-top: calc(var(--frame-width) / 2);
  font-size: 1.5rem;
}

@media (hover) {
  .ShoppingCart_continueShopping__1fGhy:hover,
  .ShoppingCart_goShopping__1Jv2v:hover,
  .ShoppingCart_titleBox__34-An:hover,
  .ShoppingCart_add__x2ibL:hover,
  .ShoppingCart_remove__2NE4x:hover {
    opacity: var(--hover);
  }
}

.ShoppingCart_body__3IeiB {
  margin: 0;
  position: relative;
  height: calc(100vh - (var(--frame-width) * 2));
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto;
}

.ShoppingCart_listFrame__1iQ2c {
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
}

.ShoppingCart_list__2HB8w {
  padding-top: 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  padding-right: 1vw;
}

.ShoppingCart_titles__2yIwB {
  grid-column: 1 / span 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  color: #9c9c9c;
  font-size: 0.9rem;
}

.ShoppingCart_quantity__3TPLW {
  justify-self: center;
}

.ShoppingCart_remove__2NE4x {
  padding: 1vw;
  cursor: pointer;
}

.ShoppingCart_add__x2ibL {
  padding: 1vw;
  cursor: pointer;
}

.ShoppingCart_total__2YT46 {
  justify-self: end;
}

.ShoppingCart_price__2m2nR {
  justify-self: center;
}

.ShoppingCart_line__38gwv {
  grid-column: 1 / span 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  height: 10vh;
  padding-top: 1vw;
  padding-bottom: 1vw;
  color: #4a4a4a;
  font-size: 0.9rem;
}

.ShoppingCart_lineBox__3_FcH {
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
}

.ShoppingCart_lineBoxTotal__sW5F9 {
  -webkit-align-self: center;
          align-self: center;
  justify-self: end;
}

.ShoppingCart_lineImage__1WkSx {
  justify-self: end;
  padding-top: 0vw;
  padding-bottom: 0vw;
  padding-right: 1vw;
  height: 10vh;
  padding-left: 0;
}

.ShoppingCart_sepLine__3RI1r {
  width: 100%;
  border-bottom: #9c9c9c solid 1px;
  grid-column: 1 / span 5;
  justify-self: center;
  align-self: center;
  padding-top: 1vw;
  opacity: 0.5;
}

.ShoppingCart_subTotalFrame__1JMQK {
  grid-column: 1 / span 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  height: 10vh;
}

.ShoppingCart_subTotalTitle__3Tgeu {
  color: #4a4a4a;
  align-self: center;
  justify-self: center;
  grid-column: 4 / span 1;
}

.ShoppingCart_subTotal__3lW87 {
  color: #4a4a4a;
  grid-column: 5 / span 1;
  align-self: center;
  justify-self: end;
  text-align: right;
}

.ShoppingCart_paypalInformation__2040q {
  padding-top: 5vh;
  font-size: 1.5rem;
  color: #e60000;
}

.ShoppingCart_checkout__1viX_ {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* position: absolute; */
  bottom: 0;
  width: 99%;
  right: 0;
  align-self: end;
  padding-right: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  overflow: hidden;
}

.ShoppingCart_checkoutTitle__sAXIn {
  grid-column: 2 / span 2;
  align-self: center;
  justify-self: end;
  padding-right: 1vw;
  color: #4a4a4a;
  font-size: 1.2rem;
}

.ShoppingCart_shippingTitle__1dSg7 {
  grid-column: 1 / span 5;
  padding-bottom: 1vw;
  color: #9c9c9c;
  justify-self: end;
  align-self: center;
  font-size: 0.9rem;
}

.ShoppingCart_checkoutButton__2VGFe {
  grid-column: 5 / span 1;
  align-self: center;
  justify-self: end;
  font-size: 0.8rem;
  color: #4a4a4a;
  cursor: pointer;
  height: auto;
  position: relative;
  bottom: -0.4em;
}

@media screen and (max-width: 1023px) {
  .ShoppingCart_paid__14gBR {
    padding: 0 3vw 20vw 5vw;
  }
  .ShoppingCart_main__1QGp9 {
    width: calc(100vw - (var(--frame-width) * 5));
  }
  .ShoppingCart_category__3FwCO {
    padding: 0.5vw 0 5vw 0;
  }
  .ShoppingCart_line__38gwv {
    grid-template-columns: 25% 75%;
    font-size: 3rem;
    height: 100%;
  }
  .ShoppingCart_title__3uxyD {
    padding-top: 1.5vw;
  }
  .ShoppingCart_box__2MckB {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .ShoppingCart_innerBox__3YJwz {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
  .ShoppingCart_subTotalFrame__1JMQK {
    font-size: 3rem;
  }

  .ShoppingCart_subTotalTitle__3Tgeu {
    justify-self: start;
    grid-column: 1 / span 1;
  }
  .ShoppingCart_checkout__1viX_ {
    bottom: 15vh;
    width: calc(100vw - (var(--frame-width) * 5));
    right: 0;
    -webkit-align-self: end;
            align-self: end;
    padding-right: 0;
    margin-bottom: 10vh;
  }
  .ShoppingCart_checkoutTitle__sAXIn {
    font-size: 3rem;
    grid-column: 1 / span 4;
    padding-right: 3vw;
  }

  .ShoppingCart_checkoutButton__2VGFe {
    grid-column: 5 / span 1;
    justify-self: center;
    align-self: center;
    height: 2vh;
    bottom: 1em;
  }
  .ShoppingCart_emptyCartInfo__2XvsE {
    font-size: 3.5em;
  }

  .ShoppingCart_goShopping__1Jv2v {
    font-size: 3.5rem;
  }
  .ShoppingCart_paypalSuccess__2fL1V {
    font-size: 4.5rem;
  }
  .ShoppingCart_continueShopping__1fGhy {
    font-size: 2.5rem;
  }
  .ShoppingCart_sepLine__3RI1r {
    width: 100%;
    border-bottom: #9c9c9c solid 1px;
    grid-column: 1 / span 5;
    justify-self: center;
    align-self: center;
    padding-top: 2vw;
  }
  .ShoppingCart_lineImage__1WkSx {
    -webkit-align-self: center;
            align-self: center;
  }
  .ShoppingCart_shippingTitle__1dSg7 {
    padding-bottom: 3vw;
    font-size: 2.5rem;
  }
  .ShoppingCart_remove__2NE4x {
    padding: 2.5vw;
  }

  .ShoppingCart_add__x2ibL {
    padding: 2.5vw;
  }
}

@media screen and (max-width: 700px) {
  .ShoppingCart_main__1QGp9 {
    width: calc(100vw - (var(--frame-width) * 5));
  }
  .ShoppingCart_category__3FwCO {
    padding: 1vw 0 7.4vw 0;
  }
  .ShoppingCart_line__38gwv {
    grid-template-columns: 30% 70%;
    font-size: 3rem;
  }
  .ShoppingCart_box__2MckB {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .ShoppingCart_innerBox__3YJwz {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
  .ShoppingCart_subTotalFrame__1JMQK {
    font-size: 3rem;
  }

  .ShoppingCart_subTotalTitle__3Tgeu {
    justify-self: start;
    grid-column: 1 / span 1;
  }
  .ShoppingCart_checkout__1viX_ {
    bottom: 15vh;
    width: calc(100vw - (var(--frame-width) * 5));
    right: 0;
    -webkit-align-self: end;
            align-self: end;
    padding-right: 0;
  }
  .ShoppingCart_checkoutTitle__sAXIn {
    font-size: 3rem;
    grid-column: 1 / span 4;
    padding-right: 3vw;
  }

  .ShoppingCart_checkoutButton__2VGFe {
    grid-column: 5 / span 1;
    justify-self: center;
    align-self: center;
    height: 2vh;
    bottom: 4em;
  }
  .ShoppingCart_emptyCartInfo__2XvsE {
    font-size: 3.5em;
  }

  .ShoppingCart_goShopping__1Jv2v {
    font-size: 3.5rem;
  }
  .ShoppingCart_lineImage__1WkSx {
    padding-right: 3vw;
  }
  .ShoppingCart_shippingTitle__1dSg7 {
    padding-bottom: 5vw;
  }
}

.Image_image__2zV0Y {
  /* object-fit: contain; */
  /* width: 100%;
  height: 100%; */
  display: block;
  position: relative;
  z-index: 10;
}
.Menu_main__u_6N5 {
  padding: var(--frame-width);
  position: fixed;
  left: 0;
  top: 0;
  width: calc(50vw - (var(--frame-width) * 2));
  height: 100vh;
  z-index: 3;
  background: #f4f4f4;
  font-size: 0.75rem;
  text-transform: uppercase;
  -webkit-transition: left var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  transition: left var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  overflow-y: scroll;
  color: #9c9c9c;
  pointer-events: initial;
}

.Menu_frame__QFZn5 {
  padding: 1.5vw;
  padding-left: 2.5vw;
}

.Menu_title__1QuBS {
  font-size: 1em;
}

.Menu_itemsCollections__3YEJa {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  max-height: 20vh;
  font-size: 2em;
  padding: 0.5vw 0;
  -webkit-columns: 10vw 2;
          columns: 10vw 2;
  grid-column-gap: 1vw;
  -webkit-column-gap: 1vw;
          column-gap: 1vw;
}

.Menu_items__37004 {
  font-size: 2em;
  padding: 0.5vw 0;
}

.Menu_item__236Xf {
  padding: 0.3vw 0;
}

.Menu_copyright__2KHy6 {
  position: absolute;
  bottom: calc(var(--frame-width) * 3);
  left: calc(var(--frame-width) + 2.5vw);
  font-size: 0.9em;
}

@media screen and (max-width: 1023px) {
  .Menu_main__u_6N5 {
    width: calc(100vw - (var(--frame-width) * 5));
    font-size: 0.5rem;
  }
  .Menu_title__1QuBS {
    font-size: 3em;
  }

  .Menu_itemsCollections__3YEJa {
    max-height: 23vh;
    font-size: 7em;
    padding: 1vw 0;
    -webkit-columns: 20vw 2;
            columns: 20vw 2;
    grid-column-gap: 5vw;
    -webkit-column-gap: 5vw;
            column-gap: 5vw;
  }

  .Menu_items__37004 {
    font-size: 7em;
    padding: 1vw 0;
  }
  .Menu_copyright__2KHy6 {
    position: absolute;
    bottom: calc(var(--frame-width) * 4);
    left: calc(var(--frame-width) + 2.5vw);
    font-size: 3em;
  }
}

@media screen and (max-width: 700px) {
  .Menu_main__u_6N5 {
    width: calc(100vw - (var(--frame-width) * 5));
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1800px) {
  .Menu_main__u_6N5 {
    font-size: 0.6rem;
  }
}

.MenuItem_main__jGEjQ {
}

.MenuItem_dot__1YrGV {
  position: absolute;
  left: -1.5vw;
  top: 0.9vw;
  height: 0.5vw;
  width: auto;
  box-sizing: border-box;
  /* padding: 0.5vw; */
  /* background: blue; */
  overflow: visible;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.MenuItem_realDot__m0pia {
  width: 0.1vw;
  height: 0.1vw;
}

.MenuItem_item__UL-o9 {
  padding: 0.3vw 0;
  position: relative;
  /* border: 1p x solid blue; */
}

@media (hover) {
  .MenuItem_item__UL-o9:hover {
    color: #4a4a4a;
  }
}

@media screen and (max-width: 1023px) {
  .MenuItem_dot__1YrGV {
    left: -4vw;
    top: 1.3vw;
    height: 3vw;
    width: 2vw;
  }
  .MenuItem_item__UL-o9 {
    padding: 0.7vw 0;
    position: relative;
  }
}

@media screen and (max-width: 700px) {
  .MenuItem_item__UL-o9 {
    padding: 1.2vw 0;
    position: relative;
  }
  .MenuItem_dot__1YrGV {
    top: 2.8vw;
  }
}

@media screen and (min-width: 1700px) {
  .MenuItem_dot__1YrGV {
    left: -1.5vw;
    top: 0.8vw;
    height: 0.5vw;
    width: auto;
  }
}

.DarkenBackground_main__34TB9 {
  -webkit-transition: opacity var(--ani-time) ease-in-out;
  transition: opacity var(--ani-time) ease-in-out;
  opacity: 1;
  background: black;
  height: 200vh;
  width: 100vw;
  overflow: hidden;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.Home_main__V1mkk {
  width: 100vw;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}

.Home_slider__1M7sv {
  position: relative;
  z-index: 0;
}

.Home_slider__1M7sv > * {
  position: static;
}

.Home_framePhone__33J6T {
  width: 100vw;
  height: auto;
}

.Home_frameDesktop__2ftf4 {
  width: 100vw;
  height: 100vh;
}

.Home_body__1dHfq {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  /* transition: transform var(--ani-time) cubic-bezier(.18,.63,.96,.51); */
  background: var(--background-color);
}

.Home_horizontalBottom__fryDT {
  position: absolute;
  bottom: 0;
  left: 0;
  height: var(--frame-width);
  width: 100vw;
  display: grid;
  grid-template-columns: auto auto;
  z-index: 10;
}

.Home_description__1hokS {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
  cursor: pointer;
}

@media (hover) {
  .Home_description__1hokS:hover,
  .Home_lookbook__WByl6:hover {
    opacity: var(--hover);
  }
}

.Home_lookbook__WByl6 {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
  cursor: pointer;
}

/* .transition-down-appear {
  transform: translateY(100vh);
} */

.Home_transition-down-appear__1hMyG {
  top: 0;
  position: absolute;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.Home_transition-down-enter__2M1Qc {
  top: 0;
  position: absolute;
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh);
}

.Home_transition-down-enter-active__B7JT5 {
  position: unset;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform 1000ms ease-out;
  transition: -webkit-transform 1000ms ease-out;
  transition: transform 1000ms ease-out;
  transition: transform 1000ms ease-out, -webkit-transform 1000ms ease-out;
}

.Home_transition-down-exit__1_l35 {
  top: 0;
  position: absolute;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.Home_transition-down-exit-active__33Z8K {
  -webkit-transform: translateY(-100vh);
          transform: translateY(-100vh);
  -webkit-transition: all 1000ms ease-out;
  transition: all 1000ms ease-out;
}

.Home_transition-down-appear__1hMyG {
  top: 0;
  position: absolute;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.Home_transition-up-enter__6Zffv {
  position: absolute;
  top: 0;
  -webkit-transform: translateY(-100vh);
          transform: translateY(-100vh);
}

.Home_transition-up-enter-active__2wac7 {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 1000ms ease-out;
  transition: all 1000ms ease-out;
}

.Home_transition-up-exit__1Za0e {
  top: 0;
  position: absolute;
  -webkit-transform: translateY(-100vh);
          transform: translateY(-100vh);
}

.Home_transition-up-exit-active__2okid {
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh);
  -webkit-transition: all 1000ms ease-out;
  transition: all 1000ms ease-out;
}

.Home_transition-down-appear__1hMyG {
  top: 0;
  position: absolute;
  -webkit-transform: translateY(50vh);
          transform: translateY(50vh);
}

.Home_transition-change-enter__1els3 {
  position: relative;
  top: -50vh;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.Home_transition-change-enter-active__1d7RW {
  position: relative;
  top: -100vh;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: all 1000ms ease-out;
  transition: all 1000ms ease-out;
}

.Home_transition-change-exit__1TYyv {
  top: -100vh;
  position: relative;
  -webkit-transform: translateY(-150vh);
          transform: translateY(-150vh);
}

.Home_transition-change-exit-active__1PuJe {
  top: -200vh;
  position: absolute;
  -webkit-transform: translateY(-50vh);
          transform: translateY(-50vh);
  -webkit-transition: all 1000ms ease-out;
  transition: all 1000ms ease-out;
}

@media screen and (max-width: 1024px) {
  .Home_horizontalBottom__fryDT {
    grid-template-columns: auto auto;
  }
  .Home_description__1hokS {
    position: absolute;
    right: calc(var(--frame-width) / 4);
    top: 50%;
    -webkit-writing-mode: tb-rl;
            writing-mode: tb-rl;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 10;
  }
  .Home_lookbook__WByl6 {
    position: absolute;
    left: calc(var(--frame-width) / 4);
    top: 50%;
    -webkit-writing-mode: tb-rl;
            writing-mode: tb-rl;
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
    z-index: 10;
  }
}

.HomeLookBook_main__2a73r {
  height: 100vh;
  width: 100%;
  z-index: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh);
  -webkit-transition: -webkit-transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  transition: -webkit-transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  transition: transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  transition: transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9), -webkit-transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  overflow: visible;
  /* background: var(--background-color); */
  background: transparent;
}

.ImageCarousel_main__3a1lc {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--background-color);
}

.ImageCarousel_slider__3sQWE {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 1000ms ease-in-out;
  transition: opacity 1000ms ease-in-out;
  z-index: 500;
}

.ImageCarousel_left__JrukO {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 800;
  height: 100%;
  width: 50%;
}

.ImageCarousel_right__i-PGz {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  z-index: 800;
  height: 100%;
}

.ImageCarousel_leftArrow__qFV_Y {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 6rem;
  z-index: 600;
  padding: var(--frame-width);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.ImageCarousel_rightArrow__3rz0w {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 6rem;
  z-index: 600;
  padding: var(--frame-width);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.ImageCarousel_selector__Ujpbs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 500;
  height: auto;
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: var(--logo-color);
  padding-bottom: calc(var(--frame-width) / 4);
}

.ImageCarousel_item__3U5ek {
  padding: 0.5vw;
  padding-bottom: 0;
  padding-top: 0;
  cursor: pointer;
}

.ImageCarousel_transition__1baD5 {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-out;
  transition: opacity 500ms ease-out;
}

.ImageCarousel_transition-appear__vdXgE {
  opacity: 1;
}

.ImageCarousel_transition-enter___q3L7 {
  opacity: 1;
}

.ImageCarousel_transition-enter-active__2FDT- {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-out;
  transition: opacity 500ms ease-out;
}

.ImageCarousel_transition-exit__2GP29 {
  opacity: 1;
  background: var(--background-color);
}

.ImageCarousel_transition-exit-active__MVuQc {
  opacity: 0;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.ImageCarousel_transition-exit-done__oMe9D {
  opacity: 0;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.ImageCarousel_circle__2MYdK {
  height: auto;
  width: 0.4vw;
}

@media screen and (max-width: 1023px) {
  .ImageCarousel_circle__2MYdK {
    height: auto;
    width: 1.5vw;
  }
}

.HomeGrid_main__3-lIf {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto;
  width: 100%;
  /* height: calc(100vh - (var(--frame-width)*2)); */
  height: 100%;
  grid-template-areas:
    'item1 item2 item4 item5'
    'item1 item3 item4 item6';
  overflow: hidden;
  padding: var(--frame-width);
  position: absolute;
  /* bottom: 0; */
  left: 0;
  top: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: -webkit-transform var(--ani-time) cubic-bezier(0.18, 0.63, 0.96, 0.51);
  transition: -webkit-transform var(--ani-time) cubic-bezier(0.18, 0.63, 0.96, 0.51);
  transition: transform var(--ani-time) cubic-bezier(0.18, 0.63, 0.96, 0.51);
  transition: transform var(--ani-time) cubic-bezier(0.18, 0.63, 0.96, 0.51), -webkit-transform var(--ani-time) cubic-bezier(0.18, 0.63, 0.96, 0.51);
  box-sizing: border-box;
}

.HomeGrid_gridItem__2uhR2 {
  -webkit-align-self: start;
          align-self: start;
  justify-self: start;
  height: 100%;
}

.HomeGrid_item1__3erPi {
  grid-area: item1;
  overflow: hidden;
}

.HomeGrid_item2__tgwEc {
  grid-area: item2;
}

.HomeGrid_item3__22m5L {
  grid-area: item3;
}

.HomeGrid_item4__1oID_ {
  grid-area: item4;
  overflow: hidden;
}

.HomeGrid_item5__sB3OL {
  grid-area: item5;
}

.HomeGrid_item6__1cgLi {
  grid-area: item6;
}

@media screen and (max-width: 1023px) {
  .HomeGrid_main__3-lIf {
    grid-template-columns: auto auto;
    grid-template-rows: 25% 25% 25% 25%;
    grid-template-areas:
      'item1 item1'
      'item3 item2'
      'item4 item4'
      'item6 item5';
    height: 90%;
    grid-gap: 0 0;
    padding-top: calc(var(--frame-width) * 2);
    box-sizing: border-box;
    width: 100%;
  }
  .HomeGrid_gridItem__2uhR2 {
    -webkit-align-self: center;
            align-self: center;
    justify-self: center;
  }

  .HomeGrid_item1__3erPi {
    -webkit-transform: scale(1.6) rotate(90deg);
            transform: scale(1.6) rotate(90deg);
    position: relative;
    width: 15vw;
    box-sizing: border-box;
  }
  .HomeGrid_item4__1oID_ {
    -webkit-transform: scale(1.6) rotate(90deg);
            transform: scale(1.6) rotate(90deg);
    position: relative;
    box-sizing: border-box;
    width: 15vw;
  }
}

.Product_main__29Sly {
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.Product_image__1zepN {
  position: relative;
  height: var(--PcColumnHeight);
  -webkit-transform: (translate(0, 0, 0));
          transform: (translate(0, 0, 0));
  -webkit-transition: skew 500ms, rotateZ 500ms, -webkit-transform 500ms;
  transition: skew 500ms, rotateZ 500ms, -webkit-transform 500ms;
  transition: transform 500ms, skew 500ms, rotateZ 500ms;
  transition: transform 500ms, skew 500ms, rotateZ 500ms, -webkit-transform 500ms;
}

.Product_productInfo__e0ZlB {
  text-align: center;
  font-size: 0.8rem;
  width: auto;
  text-align: center;
  position: relative;
}

@media screen and (max-width: 1023px) {
  .Product_main__29Sly {
    height: 100%;
    display: inline;
    display: initial;
    overflow: hidden;
  }
  .Product_image__1zepN {
    height: 100%;
  }
}

.LandingpageDescription_main__1i-LV {
  padding: var(--frame-width);
  padding-top: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(50vw - (var(--frame-width) * 2));
  height: 100vh;
  z-index: 5;
  background: #f4f4f4;
  font-size: 0.9rem;
  text-transform: uppercase;
  -webkit-transform: translateY(100vh);
          transform: translateY(100vh);
  -webkit-transition: -webkit-transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  transition: -webkit-transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  transition: transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  transition: transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9), -webkit-transform var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
  display: grid;
  grid-template-rows: 1fr;
  color: #9c9c9c;
  /* overflow-y: scroll; */
}

.LandingpageDescription_body__3pXlk {
  justify-self: center;
  -webkit-align-self: center;
          align-self: center;
  padding: var(--frame-width);
  -webkit-transform: translateY(-10%);
          transform: translateY(-10%);
}

.LandingpageDescription_title__1P3B1 {
  text-align: center;
}

.LandingpageDescription_year__3RcOS {
  text-align: center;
}

.LandingpageDescription_subtext__2eYd0 {
  text-align: center;
}

.LandingpageDescription_description__2COx3 {
  font-size: 1.5rem;
  padding: 1vw;
  text-align: justify;
  text-transform: none;
  text-transform: initial;
}

.LandingpageDescription_copyright__11FXS {
  position: fixed;
  bottom: var(--frame-width);
  left: calc(var(--frame-width) + 2.5vw);
}

@media screen and (max-width: 1023px) {
  .LandingpageDescription_main__1i-LV {
    width: calc(100vw - (var(--frame-width) * 5));
    font-size: 2rem;
  }
  .LandingpageDescription_description__2COx3 {
    font-size: 3rem;
    padding: 1vw;
    text-align: justify;
  }
}

@media screen and (max-width: 700px) {
  .LandingpageDescription_body__3pXlk {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }
  .LandingpageDescription_main__1i-LV {
    width: calc(100vw - (var(--frame-width) * 5));
    font-size: 3rem;
  }
  .LandingpageDescription_description__2COx3 {
    font-size: 4rem;
    padding: 1vw;
    text-align: justify;
  }
}

.Shop_main__3vG1Q {
  position: relative;
  z-index: 0;
  /* overflow: hidden; */
}

@media screen and (max-width: 1023px) {
  .Shop_main__3vG1Q {
    width: 100vw;
  }
}

.ShopItems_main__HR_LI {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  height: calc(100vh - (var(--frame-width)*2));
  grid-gap: 1vw 1vw;
  padding: var(--frame-width);
  padding-top: calc(var(--frame-width)*2);
}

@media screen and (max-width: 1023px) {
  .ShopItems_main__HR_LI {
    grid-template-columns: auto auto;
    padding: 0;
    padding-top: calc(var(--frame-width)*2);
  }
}
.ShopItemsProduct_main__t3vHQ {
  justify-self: start;
  align-self: start;
  width: calc((100vw - var(--frame-width)*2.7) / 3);
  height: calc((100vw - var(--frame-width)*2.7) / 3);
  padding-top: calc(var(--frame-width) / 2);
  padding-bottom: calc(var(--frame-width) / 2);
  display: grid;
  grid-template-rows: calc(88vw / 3);
  position: relative;
}

.ShopItemsProduct_information__X07W3 {
  padding-top: 10%;
  text-align: center;
  height: 10%;
  font-size: 0.8rem;
  position: absolute;
  bottom: 0.6vh;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 500;
}

@media screen and (max-width: 1023px) {
  .ShopItemsProduct_main__t3vHQ {
    width: calc(100vw / 2);
    height: calc(100vw / 2);
    grid-template-rows: 80% 20%;
    padding: 0;
    padding-bottom: 10vh;
  }

  .ShopItemsProduct_information__X07W3 {
    padding: 0;
    font-size: inherit;
    padding-bottom: calc(var(--frame-width) / 2);
    bottom: 7.6vh;
  }
}

.ShopDetail_main__1-Mlz {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: 
      "item2"
      "item1";
  height: 100vh;
}

.ShopDetail_left__2nIhl {
  grid-area: item1;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.ShopDetail_right__qPUwD {
  grid-area: item2;
  width: 100vw;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  .ShopDetail_main__1-Mlz {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 
      "item1 item2";
  }
  .ShopDetail_right__qPUwD {
    width: 50vw;
  }
  .ShopDetail_left__2nIhl {
    height: 100vh;
    width: 50vw;
  }
}
.ShopDetailProduct_main__1zwII {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 75vh auto;
  height: 100%;
  position: relative;
  font-size: 3.5rem;
  text-transform: uppercase;
  z-index: 400;
  overflow-y: scroll;
}

.ShopDetailProduct_upperFrame__4VWiY {
  height: 100%;
  overflow: scroll;
  /* border-bottom: 1px solid #9c9c9c; */
}

.ShopDetailProduct_form__3al3P {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 0.3fr 1fr 0.7fr 0.9fr;
  grid-template-areas:
    'information'
    'price'
    'item1'
    'item2'
    'addtocart';
  align-self: center;
  padding-left: var(--frame-width);
  padding-right: var(--frame-width);
  padding-bottom: calc(var(--frame-width) / 2);
  padding-top: calc(var(--frame-width) / 2);
  position: relative;
  width: 100%;
  background: transparent;
  z-index: 900;
  box-sizing: border-box;
  background: #e3e3e3;
}

.ShopDetailProduct_information__fsYyT {
  grid-area: information;
  justify-self: center;
  align-self: start;
  cursor: pointer;
  font-size: 1.3em;
}

@media (hover) {
  .ShopDetailProduct_information__fsYyT:hover,
  .ShopDetailProduct_addToCart__1ocLh:hover {
    opacity: var(--hover);
  }
}

.ShopDetailProduct_formError__cksWb {
  grid-area: formError;
  color: #ea5151;
  font-size: 0.7em;
  position: absolute;
  bottom: 15vw;
  width: 100%;
  text-align: center;
}

.ShopDetailProduct_addToCart__1ocLh {
  width: 100%;
  height: 7vw;
  text-align: center;
  font-size: 3.5rem;
  border: 1px solid var(--text-color);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ShopDetailProduct_addToCartDiv__2cm2c {
  grid-area: addtocart;
  justify-self: center;
  align-self: end;
  padding-bottom: 1vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 60%;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ShopDetailProduct_shippingInformation__xpLxq {
  padding-top: 1vw;
  width: 100%;
  text-align: center;
  font-size: 0.6em;
}

.ShopDetailProduct_price__3xl5Y {
  grid-area: price;
  justify-self: center;
  padding: 0;
  justify-self: center;
  text-align: center;
  grid-column: 1 / span 2;
}

.ShopDetailProduct_item1__1hMvx {
  grid-area: item1;
  justify-self: center;
  padding-top: 4vh;
  padding-bottom: 1vh;
  text-align: justify;
  width: 60%;
}

.ShopDetailProduct_item2__2eNZe {
  grid-area: item2;
  justify-self: center;
  padding-top: 1vh;
  padding-bottom: 1vh;
  text-align: justify;
  width: 60%;
}

@media screen and (min-width: 1024px) {
  .ShopDetailProduct_main__1zwII {
    font-size: 1rem;
    overflow: hidden;
    grid-template-rows: calc(98vh - var(--frame-width)) auto var(--frame-width);
  }
  .ShopDetailProduct_form__3al3P {
    display: grid;
    grid-template-columns: 20% 10% auto 10% 20%;
    grid-template-areas: 'item1 item2 information price addtocart';
    align-self: center;
    /* bottom: 0;
    right: 0; */
    height: auto;
    padding: 0;
    width: 100%;
    padding-left: calc(var(--frame-width) / 2);
    padding-right: calc(var(--frame-width) / 2);
    /* position: absolute; */
    background: var(--background-color);
  }

  .ShopDetailProduct_formError__cksWb {
    bottom: 4vw;
    right: -17.3vw;
    width: 100%;
    text-align: center;
  }

  .ShopDetailProduct_price__3xl5Y {
    padding-top: 0;
    padding-right: 0;
    text-align: center;
    justify-self: end;
    grid-column: 4 / span 1;
  }
  .ShopDetailProduct_information__fsYyT {
    justify-self: center;
    -webkit-align-self: center;
            align-self: center;
    cursor: pointer;
    padding-top: 0;
    font-size: 1em;
  }

  .ShopDetailProduct_addToCartDiv__2cm2c {
    grid-area: addtocart;
    justify-self: center;
    align-self: end;
    padding-bottom: 0vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    position: static;
    position: initial;
    padding-left: 1vw;
  }
  .ShopDetailProduct_addToCart__1ocLh {
    justify-self: end;
    -webkit-align-self: center;
            align-self: center;
    font-size: 0.9rem;
    padding: 0.4vw;
    height: auto;
    vertical-align: middle;
    text-align: center;
  }

  .ShopDetailProduct_formError__cksWb {
    left: -37.5%;
    bottom: 11.2vw;
  }

  .ShopDetailProduct_shippingInformation__xpLxq {
    padding-top: 0;
    position: relative;
    text-align: right;
    right: calc(var(--frame-width) / 2);
    font-size: 0.8rem;
  }

  .ShopDetailProduct_price__3xl5Y {
    -webkit-align-self: center;
            align-self: center;
    padding: 0;
  }

  .ShopDetailProduct_item1__1hMvx {
    -webkit-align-self: center;
            align-self: center;
    padding: 0;
    padding-right: 0;
    width: 100%;
    text-align: left;
  }
  .ShopDetailProduct_item2__2eNZe {
    -webkit-align-self: center;
            align-self: center;
    padding: 0;
    width: 100%;
  }
}

.DropDownList_main__3k_Kr {
  position: relative;
  cursor: pointer;
  display: grid;
}

.DropDownList_ulList__1Krgi {
  position: absolute;
  bottom: var(--frame-width);
  width: 30vw;
  list-style-type: none;
  padding: 0;
}

.DropDownList_ulListItem__RdGYO {
  padding-top: 0.4vw;
  position: relative;
}

@media (hover) {
  .DropDownList_header__2zFwJ:hover {
    opacity: var(--hover);
  }
  .DropDownList_ulListItem__RdGYO:hover {
    color: #4a4a4a;
  }
}

.DropDownList_dot__2olFR {
  width: 0.5vw;
  position: absolute;
  left: -0.8vw;
  top: 0.2vw;
  height: 0.4vw;
}

.DropDownList_deepDot__2SBH1 {
  overflow: visible;
}

@media screen and (max-width: 1024px) {
  .DropDownList_select__2eaYJ {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #e3e3e3;
    border: none;
    color: var(--text-color);
    font-size: 1em;
    font-family: Opale, sans-serif;
    text-transform: uppercase;
  }

  @media (hover) {
    .DropDownList_headerFrame__kkNOU:hover {
      opacity: 0.8;
    }
  }

  .DropDownList_headerFrame__kkNOU {
    display: grid;
    grid-template-columns: 90% 10%;
    width: 100%;
  }

  .DropDownList_plus__aJwVB {
    justify-self: end;
  }
}

.ShopDetailProductInformation_main__3AIfD {
  display: grid;
  grid-template-rows: 2% auto auto;
  padding: var(--frame-width);
  padding-top: 6vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ShopDetailProductInformation_title__31ErL {
  text-align: center;
}

.ShopDetailProductInformation_description__3jTos {
  font-size: 1.5rem;
  /* padding: 2vw; */
  text-align: justify;
  text-transform: none;
  text-transform: initial;
}

.ShopDetailProductInformation_extra__CKolW {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  font-size: 1rem;
  grid-column-gap: 2vw;
  -webkit-column-gap: 2vw;
          column-gap: 2vw;
}

.ShopDetailProductInformation_extraTitle__2Uc0d {
  padding: 1vw;
  text-align: center;
  text-transform: uppercase;
}

.ShopDetailProductInformation_extraGroup__2NH9S {
  justify-self: center;
  width: 100%;
  box-sizing: border-box;
}

.ShopDetailProductInformation_fillItem__1KiQT {
  display: none;
}

@media screen and (max-width: 1023px) {
  .ShopDetailProductInformation_main__3AIfD {
    padding-top: 15vw;
    font-size: 3rem;
    height: 100%;
  }
  .ShopDetailProductInformation_description__3jTos {
    padding: var(--frame-width);
    font-size: 3.5rem;
  }
  .ShopDetailProductInformation_extra__CKolW {
    padding: var(--frame-width);
  }
  .ShopDetailProductInformation_extra__CKolW {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    font-size: 3.5rem;
  }
  .ShopDetailProductInformation_extraTitle__2Uc0d {
    padding-top: 6vw;
  }

  .ShopDetailProductInformation_fillItem__1KiQT {
    height: 8vh;
    width: 100%;
    display: inline;
    display: initial;
  }
}

@media screen and (max-width: 700px) {
  .ShopDetailProductInformation_main__3AIfD {
    padding-top: 15vw;
    font-size: 4.5rem;
    overflow: scroll;
    height: 100%;
  }
  .ShopDetailProductInformation_description__3jTos {
    padding: var(--frame-width);
    font-size: 4.5rem;
  }
  .ShopDetailProductInformation_extra__CKolW {
    padding: var(--frame-width);
  }
  .ShopDetailProductInformation_extra__CKolW {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    font-size: 4.5rem;
  }
}

.ViewerSplit_main__21pKL {
  display: grid;
  font-size: 2vmin;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1023px) {
  .ViewerSplit_main__21pKL {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
}
.About_main__1rWB0 {
  padding: var(--frame-width);
  overflow: auto;
}

.About_main__1rWB0 p {
  padding: 0;
  margin: 0;
  font-size: 1.1em;
}

.About_main__1rWB0 h2 {
  font-size: 0.8em;
  font-weight: normal;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}

.About_main__1rWB0 h3 {
  font-size: 0.8em;
  font-weight: normal;
  padding: 0;
  text-align: center;
}

.About_left__2QN4U {
  align-self: start;
  height: 100%;
  display: grid;
  font-size: 3.5rem;
  text-align: center;
}

.About_right__3Zv1c {
  height: calc(100vh - (var(--frame-width) * 3));
  padding-top: var(--frame-width);
  font-size: 3.5rem;
  display: grid;
}

.About_about__1TzSR {
  -webkit-align-self: start;
          align-self: start;
  padding: 0;
  padding-top: var(--frame-width);
  line-height: 150%;
  font-size: 3rem;
}

.About_rest__1e2fC {
  -webkit-align-self: start;
          align-self: start;
  padding: 0;
  padding-top: calc(var(--frame-width) * 2);
  font-size: 2.5rem;
  line-height: 130%;
}

@media screen and (max-width: 700px) {
  .About_about__1TzSR {
    -webkit-align-self: start;
            align-self: start;
    padding: 0;
    padding-top: var(--frame-width);
    line-height: 150%;
    font-size: 3.5rem;
  }

  .About_rest__1e2fC {
    -webkit-align-self: start;
            align-self: start;
    padding: 0;
    padding-top: calc(var(--frame-width) * 2);
    font-size: 3rem;
    line-height: 130%;
  }
}

@media screen and (min-width: 1024px) {
  .About_left__2QN4U {
    -webkit-align-self: start;
            align-self: start;
    font-size: 1.8rem;
    height: calc((1vh * 100) - (var(--frame-width) * 2));
    height: calc((var(--vh, 1vh) * 100) - (var(--frame-width) * 2));
  }
  .About_right__3Zv1c {
    margin: 0;
    padding: 0;
    -webkit-align-self: start;
            align-self: start;
    font-size: 1.8rem;
    height: calc((1vh * 100) - (var(--frame-width) * 2));
    height: calc((var(--vh, 1vh) * 100) - (var(--frame-width) * 2));
  }
  .About_about__1TzSR {
    -webkit-align-self: center;
            align-self: center;
    padding: 0;
    padding-left: calc(var(--frame-width) / 2);
    padding-bottom: 0;
    text-align: left;
    font-size: 1.5rem;
  }

  .About_rest__1e2fC {
    -webkit-align-self: center;
            align-self: center;
    padding: 10vw;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    line-height: 150%;
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1700px) {
  .About_left__2QN4U {
    font-size: 1.2rem;
    padding: 0;
  }
  .About_right__3Zv1c {
    font-size: 1.2rem;
    padding: 0;
  }
  .About_about__1TzSR {
    font-size: 1rem;
  }

  .About_rest__1e2fC {
    font-size: 1rem;
  }
}

.Imprint_main__1Oh1X {
  text-align: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding-top: calc(var(--frame-width) * 3);
  overflow: auto;
}

.Imprint_main__1Oh1X p {
  padding: 0;
  margin: 0;
  font-size: 1.1em;
}

.Imprint_main__1Oh1X h2 {
  font-size: 0.8em;
  font-weight: normal;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
}

.Imprint_fillDiv__2OP6d {
  height: 10vh;
}

@media screen and (min-width: 1024px) {
  .Imprint_main__1Oh1X {
    font-size: 1rem;
    padding-top: calc(var(--frame-width) * 1);
  }
}

@media screen and (min-width: 1700px) {
  .Imprint_main__1Oh1X {
    font-size: 1rem;
  }
}

.Policies_main__2VKjR {
  width: 86vw;
  height: 90vh;
  padding-top: calc(var(--frame-width) / 4);
  z-index: auto;
  margin-left: 7vw;
  -webkit-user-select: initial;
     -moz-user-select: initial;
      -ms-user-select: initial;
          user-select: initial;
  overflow: auto;
}

.Policies_header__3_UNR {
  font-size: 0.85em;
  text-transform: uppercase;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  background: var(--background-color);
  height: auto;
  overflow-x: scroll;
  text-align: left;
  padding-top: 10vh;
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  z-index: 2;
}

.Policies_headerItem__1AlnD {
  padding-right: 0;
  cursor: pointer;
  display: inline-block;
}

.Policies_headerItemBlock__19C_z {
  display: block;
  padding: 1vw;
}

.Policies_body__d_a1w {
  padding-top: calc(var(--frame-width) * 2);
  -webkit-columns: 90vw 1;
          columns: 90vw 1;
  grid-column-gap: 5vw;
  -webkit-column-gap: 5vw;
          column-gap: 5vw;
  font-size: 0.8em;
  color: #d8d8d8;
}

.Policies_body__d_a1w p {
  padding: 0;
  margin: 0;
}

.Policies_body__d_a1w h2 {
  font-size: 1.1em;
  font-weight: normal;
  padding: 0;
  text-align: left;
  text-transform: uppercase;
  z-index: 100;
}

.Policies_body__d_a1w h3 {
  font-size: 1em;
  font-weight: normal;
  padding: 0;
  text-align: left;
}

@media (hover) {
  .Policies_headerItem__1AlnD:hover {
    opacity: var(--hover);
  }
}

@media screen and (min-width: 1024px) {
  .Policies_main__2VKjR {
    font-size: 2rem;
  }
  .Policies_header__3_UNR {
    position: fixed;
    font-size: 1rem;
    top: 0;
    left: calc(var(--frame-width) * 2);
    width: 87vw;
    height: var(--frame-width);
    overflow-x: scroll;
    text-align: center;
    padding-top: 0;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .Policies_headerItem__1AlnD {
    padding-right: 2vw;
  }
  .Policies_body__d_a1w {
    padding-top: calc(var(--frame-width) * 1.5);
    -webkit-columns: 40vw 2;
            columns: 40vw 2;
    grid-column-gap: 5vw;
    -webkit-column-gap: 5vw;
            column-gap: 5vw;
    font-size: 0.5em;
  }
}

@media screen and (min-width: 1700px) {
  .Policies_main__2VKjR {
    font-size: 1.5rem;
  }
  .Policies_header__3_UNR {
    font-size: 1rem;
  }
}

