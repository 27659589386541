.main {
  padding: var(--frame-width);
  position: fixed;
  right: 0;
  top: 0;
  width: calc(50vw - (var(--frame-width) * 2));
  height: 100vh;
  z-index: 4;
  background: #f4f4f4;
  font-size: 1.2rem;
  transition: right var(--ani-time) cubic-bezier(0.25, 0.6, 0.54, 0.9);
}

.paid {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3vw 12vw 5vw;
  box-sizing: border-box;
}

.title {
  text-transform: uppercase;
}

.emptyCart {
  display: flex;
  justify-content: center;
  height: calc(100vh - var(--frame-width) * 4);
}

.emptyFrame {
  align-self: center;
  text-align: center;
  display: grid;
  grid-template-rows: auto auto;
  width: 100%;
}

.emptyCartInfo {
  padding-top: calc(var(--frame-width) / 2);
  font-size: 1.5em;
  padding-bottom: 1vw;
  justify-self: center;
  align-self: center;
}

.goShopping {
  cursor: pointer;
  font-size: 1.5rem;
  border: solid 1px;
  display: grid;
  padding: 1vw;
  justify-self: center;
  align-self: end;
}

.goShoppingText {
  justify-self: center;
  align-self: center;
}

.paypalSuccess {
  font-size: 2.5rem;
  justify-self: center;
  align-self: center;
  padding-bottom: 3vw;
}

.continueShopping {
  text-align: right;
  cursor: pointer;
  padding-top: calc(var(--frame-width) / 2);
  font-size: 1.5rem;
}

@media (hover) {
  .continueShopping:hover,
  .goShopping:hover,
  .titleBox:hover,
  .add:hover,
  .remove:hover {
    opacity: var(--hover);
  }
}

.body {
  margin: 0;
  position: relative;
  height: calc(100vh - (var(--frame-width) * 2));
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.listFrame {
  flex: 1 0 auto;
}

.list {
  padding-top: 2vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  padding-right: 1vw;
}

.titles {
  grid-column: 1 / span 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  color: #9c9c9c;
  font-size: 0.9rem;
}

.quantity {
  justify-self: center;
}

.remove {
  padding: 1vw;
  cursor: pointer;
}

.add {
  padding: 1vw;
  cursor: pointer;
}

.total {
  justify-self: end;
}

.price {
  justify-self: center;
}

.line {
  grid-column: 1 / span 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  height: 10vh;
  padding-top: 1vw;
  padding-bottom: 1vw;
  color: #4a4a4a;
  font-size: 0.9rem;
}

.lineBox {
  align-self: center;
  justify-self: center;
}

.lineBoxTotal {
  align-self: center;
  justify-self: end;
}

.lineImage {
  justify-self: end;
  padding-top: 0vw;
  padding-bottom: 0vw;
  padding-right: 1vw;
  height: 10vh;
  padding-left: 0;
}

.sepLine {
  width: 100%;
  border-bottom: #9c9c9c solid 1px;
  grid-column: 1 / span 5;
  justify-self: center;
  align-self: center;
  padding-top: 1vw;
  opacity: 0.5;
}

.subTotalFrame {
  grid-column: 1 / span 5;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  height: 10vh;
}

.subTotalTitle {
  color: #4a4a4a;
  align-self: center;
  justify-self: center;
  grid-column: 4 / span 1;
}

.subTotal {
  color: #4a4a4a;
  grid-column: 5 / span 1;
  align-self: center;
  justify-self: end;
  text-align: right;
}

.paypalInformation {
  padding-top: 5vh;
  font-size: 1.5rem;
  color: #e60000;
}

.checkout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* position: absolute; */
  bottom: 0;
  width: 99%;
  right: 0;
  align-self: end;
  padding-right: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.checkoutTitle {
  grid-column: 2 / span 2;
  align-self: center;
  justify-self: end;
  padding-right: 1vw;
  color: #4a4a4a;
  font-size: 1.2rem;
}

.shippingTitle {
  grid-column: 1 / span 5;
  padding-bottom: 1vw;
  color: #9c9c9c;
  justify-self: end;
  align-self: center;
  font-size: 0.9rem;
}

.checkoutButton {
  grid-column: 5 / span 1;
  align-self: center;
  justify-self: end;
  font-size: 0.8rem;
  color: #4a4a4a;
  cursor: pointer;
  height: auto;
  position: relative;
  bottom: -0.4em;
}

@media screen and (max-width: 1023px) {
  .paid {
    padding: 0 3vw 20vw 5vw;
  }
  .main {
    width: calc(100vw - (var(--frame-width) * 5));
  }
  .category {
    padding: 0.5vw 0 5vw 0;
  }
  .line {
    grid-template-columns: 25% 75%;
    font-size: 3rem;
    height: 100%;
  }
  .title {
    padding-top: 1.5vw;
  }
  .box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .innerBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
  .subTotalFrame {
    font-size: 3rem;
  }

  .subTotalTitle {
    justify-self: start;
    grid-column: 1 / span 1;
  }
  .checkout {
    bottom: 15vh;
    width: calc(100vw - (var(--frame-width) * 5));
    right: 0;
    align-self: end;
    padding-right: 0;
    margin-bottom: 10vh;
  }
  .checkoutTitle {
    font-size: 3rem;
    grid-column: 1 / span 4;
    padding-right: 3vw;
  }

  .checkoutButton {
    grid-column: 5 / span 1;
    justify-self: center;
    align-self: center;
    height: 2vh;
    bottom: 1em;
  }
  .emptyCartInfo {
    font-size: 3.5em;
  }

  .goShopping {
    font-size: 3.5rem;
  }
  .paypalSuccess {
    font-size: 4.5rem;
  }
  .continueShopping {
    font-size: 2.5rem;
  }
  .sepLine {
    width: 100%;
    border-bottom: #9c9c9c solid 1px;
    grid-column: 1 / span 5;
    justify-self: center;
    align-self: center;
    padding-top: 2vw;
  }
  .lineImage {
    align-self: center;
  }
  .shippingTitle {
    padding-bottom: 3vw;
    font-size: 2.5rem;
  }
  .remove {
    padding: 2.5vw;
  }

  .add {
    padding: 2.5vw;
  }
}

@media screen and (max-width: 700px) {
  .main {
    width: calc(100vw - (var(--frame-width) * 5));
  }
  .category {
    padding: 1vw 0 7.4vw 0;
  }
  .line {
    grid-template-columns: 30% 70%;
    font-size: 3rem;
  }
  .box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .innerBox {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }
  .subTotalFrame {
    font-size: 3rem;
  }

  .subTotalTitle {
    justify-self: start;
    grid-column: 1 / span 1;
  }
  .checkout {
    bottom: 15vh;
    width: calc(100vw - (var(--frame-width) * 5));
    right: 0;
    align-self: end;
    padding-right: 0;
  }
  .checkoutTitle {
    font-size: 3rem;
    grid-column: 1 / span 4;
    padding-right: 3vw;
  }

  .checkoutButton {
    grid-column: 5 / span 1;
    justify-self: center;
    align-self: center;
    height: 2vh;
    bottom: 4em;
  }
  .emptyCartInfo {
    font-size: 3.5em;
  }

  .goShopping {
    font-size: 3.5rem;
  }
  .lineImage {
    padding-right: 3vw;
  }
  .shippingTitle {
    padding-bottom: 5vw;
  }
}
